<template>
    <div class="body" :class="{'p-2 bg-none': alert.show}">
        <div v-if="alert.show" class="d-flex flex-column justify-content-center align-items-center">
            <div class="text-center">
                <img src="/images/oops.png" alt="OOPS!">
            </div>
            <div class="alert alert-dark" role="alert">
                <span>{{alert.message}}</span>
                <hr />
                <div class="row">
                    <div class="col pr-0">
                        <input class="form-control" type="text" placeholder="Código" v-model="code" maxlength="10">
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-warning btn-block" @click="ticketByCode(code)">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="ticket-online" v-html="template"></div>
        <nav class="navbar" v-show="!alert.show && !loading">
            <div class="row w-100">
                <template v-if="showLogo">
                    <div class="col text-right pr-0">
                        <small class="text-white">Desenvolvido por</small>
                    </div>
                    <div class="col text-left pl-0">
                        <a href="https://betsnow.net">
                            <img src="https://demo.betsnow.net/images/logo.png" height="30" alt="BetsNOW">
                        </a>
                    </div>
                </template>
                <template v-else>
                    <div class="col text-right pr-0">
                        <small class="text-white">Jogo responsável</small>
                    </div>
                    <div class="col text-left">
                        <a href="https://www.gamblingtherapy.org/pt-br/" target="_blank">
                            <img src="/images/GT_v2.svg" height="30" alt="BetsNOW">
                        </a>
                    </div>
                </template>
            </div>
        </nav>
      <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
    </div>
</template>

<script>
import {api} from '../api'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
    data() {
        return {
            code: '',
            bets: [],
            whatsapp: '',
            sorteio: {
                ativo: false,
                ganhou: false,
                codigo: '',
                nome: '',
                data_sorteio: ''
            },
            cashout: {
                active: false,
                percent: 0
            },
            message: '',
            template: '',
            commission: 0,
            loading: false,
            showLogo: false,
            alert: {
                show: false,
                message: ''
            },
            configs: {
                max_valor: 0,
                multiplicador: 0
            },
            bet_value: 0,
            total_guesses: 0,
            total_number_of_hits: 0,
            total_odds_number_of_hits: []
        }
    },
    components: {
        Loading
    },
    methods: {
        ticketByCode(code) {

            const self = this;

            if (!code) return;

            self.code = code;
            self.loading = true;
            self.alert.show = false;

            api.get(`bet/anonymous/${code}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.bets = response.data.bets;
                        self.configs = response.data.configs;
                        self.sorteio = response.data.sorteio;
                        self.cashout = response.data.cashout;
                        self.whatsapp = response.data.whatsapp;
                        self.showLogo = response.data.showLogo;
                        self.commission = response.data.comissao_aposta_premiada;
                        self.message = response.data.message.replace(/\n/g, "<br />");
                        self.viewBet(response.data.id);
                    break;
                    case 'nothing_found':
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = 'Não foi possível encontrar a aposta. Por favor, verifique o código e tente novamente!';
                    break;
                    default:
                        self.loading = false;
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.loading = false;
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message']
                }
            });
        },
        viewBet(id) {

            const self = this;

            api.get(`view-bet/${id}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountView(id, response.data.hunches);
                    break;
                    default:
                        self.alert.show = true;
                        self.alert.message = response.data.message;
                    break;
                }
            }).catch((error) => {
                try {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[error.status]['message'];
                } catch(e) {
                    self.alert.show = true;
                    self.alert.message = self.network_erros[408]['message'];
                }
            }).finally(() => {
                self.loading = false;
            });
        },
        goCashout() {
            let html = ``;
            if (this.cashout.active && this.cashout.percent > 0 && (this.total_guesses - this.total_number_of_hits) == 1) {
                html = `
                    <div class="alert alert-info mt-3 text-center" role="alert">
                        <div class="d-flex flex-column align-items-center justify-content-center">
                            <div class="display-4 mb-2">
                                <i class="fas fa-hand-holding-usd"></i>    
                            </div>
                            <div>
                                <h6 class="alert-heading">Pagamento antecipado disponível</h6>    
                            </div>    
                        </div>
                        <hr>
                        <p class="mb-0">Encerre sua aposta agora e receba <strong>R$ ${this.cashoutCalcPremium()}.</strong></p>
                        <p class="mb-0 font-bold">Para encerrar sua aposta, entre em contato com seu vendedor.</p>
                    </div>
                `;
            }
            return html;
        },
        cashoutCalcPremium() {

            let total = 1;
            let money = parseFloat(this.bet_value);

            for (let odd of this.total_odds_number_of_hits) {
                total *= parseFloat(odd);
            }

            let max_valor = this.configs.max_valor;
            let premium = parseFloat((total * money)).toFixed(2) || 0;

            if (premium > parseFloat((this.configs.multiplicador * money))) {
                premium = parseFloat((this.configs.multiplicador * money)).toFixed(2);
            }

            if (premium > parseFloat(max_valor)) {
                premium = parseFloat(max_valor).toFixed(2);
            }

            let commission = (premium / 100) * this.cashout.percent;
            let newPremium = parseFloat(premium - commission); 

            return this.formatCoin(newPremium);
        },
        mountView(id, hunches) {
            try {

                let html = ``;

                const bet = this.bets.find((item) => item.id == id);
                const timenow = moment().tz('America/Recife').format();

                if (bet instanceof Object) {
                    
                    let anteior_value_team = 0;
                    let hunches_order = this.orderByScheduleByBet(hunches);
                    let commission = (bet.premium / 100) * this.commission;

                    this.bet_value = bet.value;

                    for (let key in hunches_order) {

                        let total_cards = 0;
                        let total_corners = 0;
                        
                        let hun = hunches_order[key];

                        if (hun.statistics instanceof Object) {

                            if (Object.keys(hun.statistics.cartoes).length > 0) {
                                total_cards = hun.statistics.cartoes.total;
                            }

                            if (Object.keys(hun.statistics.escanteios).length > 0) {
                                total_corners = hun.statistics.escanteios.total;
                            }
                        }

                        if (hun.situation == 1 && hun.schedule > timenow) {
                            this.total_number_of_hits += 1;
                            this.total_odds_number_of_hits.push(hun.odd)
                        }

                        this.total_guesses += 1;

                        html += `
                        <div class="card mt-3">
                            ${anteior_value_team != hun.id ? `
                                <div class="card-header">
                                <div class="row">
                                    <div class="col text-style text-theme text-truncate">
                                        <i class="fas fa-trophy-alt mr-2"></i>${hun.league}
                                    </div>
                                    <div class="col-auto text-style text-theme">${this.convertDate(hun.schedule)}</div>    
                                </div>
                            </div>
                            ` : ''}
                            <div class="card-body">
                                ${anteior_value_team != hun.id ? `
                                    <div class="row align-items-center mb-3">
                                        ${this.isMobile() == 'desktop' ? `
                                            <div class="col text-truncate">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div>
                                                        <img class="align-middle object-fit" src="${this.getTeamImage(hun.team_home)}" onerror="this.src='/images/shield_default.png'" onload="imageCheck(this)" width="38" height="38" border="0">    
                                                    </div>
                                                    <div class="text-truncate text-center w-100">${hun.team_home}</div>    
                                                </div>
                                            </div>
                                            <div class="col-auto text-center">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div>
                                                        <img src="/images/versus_symbol.png" border="0" alt="X">
                                                    </div>
                                                    <div class="text-truncate text-center w-100">${this.convertHour(hun.schedule)}</div>    
                                                </div>
                                            </div>
                                            <div class="col text-truncate">
                                                <div class="d-flex flex-column align-items-center justify-content-center">
                                                    <div>
                                                        <img class="align-middle object-fit" src="${this.getTeamImage(hun.team_away)}" onerror="this.src='/images/shield_default.png'" onload="imageCheck(this)" width="38" height="38" border="0">    
                                                    </div>
                                                    <div class="text-truncate text-center w-100">${hun.team_away}</div>    
                                                </div>    
                                            </div> 
                                        ` : `
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <div class="d-flex flex-row align-items-center justify-content-start">
                                                            <div>
                                                                <img class="shield" src="${this.getTeamImage(hun.team_home)}" onerror="this.src='/images/shield_default.png'" onload="imageCheck(this)" width="20" height="20" border="0">   
                                                            </div>
                                                            <div class="text-truncate ml-2 w-100">${hun.team_home}</div>  
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-right">Hora</div>
                                                    <div class="w-100"></div>
                                                    <div class="col-8">
                                                        <div class="d-flex flex-row align-items-center justify-content-start">
                                                            <div>
                                                                <img class="shield" src="${this.getTeamImage(hun.team_away)}" onerror="this.src='/images/shield_default.png'" onload="imageCheck(this)" width="20" height="20" border="0">   
                                                            </div>
                                                            <div class="text-truncate ml-2 w-100">${hun.team_away}</div>  
                                                        </div>
                                                    </div>
                                                    <div class="col-4 text-truncate text-right">
                                                        <div class="text-theme">${this.convertHour(hun.schedule)}</div>    
                                                    </div>
                                                </div>    
                                            </div>
                                        `}   
                                    </div><!-- /row -->
                                    <hr />
                                ` : `
                                    <div class="betbuilder-arrow">
                                        <i class="fas fa-arrows-v"></i>
                                    </div>  
                                `}
                                <div class="row mb-3">
                                    <div class="col">Modalidade</div>
                                    <div class="col text-right">${hun.betbuilder ? 'Criar aposta': this.findModality(hun.sport)}</div>
                                    <div class="w-100"></div>
                                    <div class="col">Situação</div>
                                    <div class="col text-right ${hun.situation == 1 || hun.status == 'g' ? 'text-success' : hun.situation == 2 || hun.status == 'p' ? 'text-danger' : hun.situation == 3 ? 'text-primary' : hun.situation == 4 || hun.status == 'c' ? 'text-warning' : 'text-info'}">${hun.status == 'g' ? 'Acertou' : hun.status == 'p' ? 'Perdeu' : hun.status == 'c' ? 'Cancelado' : this.findSituation(hun.situation)}</div>
                                    <div class="w-100"></div>
                                    <div class="col">Cotação</div>
                                    <div class="col text-right">${'R$ ' + hun.odd}</div>
                                    <div class="w-100"></div>
                                    <div class="col">Ao vivo</div>
                                    <div class="col text-right">${hun.live ? 'Sim' : 'Não'}</div>
                                </div><!-- /row -->
                                ${hun.id != anteior_value_team && hun.sport == 1 && (hun.situation == 1 || hun.situation == 2) ? `
                                    <nav class="nav nav-pills nav-justified">
                                        <a class="nav-item nav-link active" id="nav-results-tab-${key}" data-toggle="tab" href="#nav-results-${key}" role="tab" aria-controls="nav-results-${key}" aria-selected="true">Resultados</a>
                                        <a class="nav-item nav-link" id="nav-statistics-tab-${key}" data-toggle="tab" href="#nav-statistics-${key}" role="tab" aria-controls="nav-statistics-${key}" aria-selected="false">Estatísticas</a>
                                    </nav>
                                    <div class="tab-content mb-2" id="nav-tabContent">
                                        <div class="tab-pane fade show active" id="nav-results-${key}" role="tabpanel" aria-labelledby="nav-results-tab-${key}">
                                            <div class="row w-100 m-0">
                                                <div class="col text-center pt-2">Final</div>
                                                <div class="col text-center pt-2">1T</div>
                                                <div class="col text-center pt-2">2T</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center text-theme">${hun.scoreboards.ft}</div>
                                                <div class="col text-center text-theme">${hun.scoreboards.pt}</div>
                                                <div class="col text-center text-theme">${hun.scoreboards.st}</div>
                                            </div>    
                                        </div>
                                        <div class="tab-pane fade" id="nav-statistics-${key}" role="tabpanel" aria-labelledby="nav-statistics-tab-${key}">
                                            <div class="row w-100 m-0">
                                                <div class="col text-center pt-2"><img class="mr-2 mb-1" src="/images/corner.png" height="15" alt="Corner">Escanteios</div>
                                                <div class="col text-center pt-2"><img class="mr-2 mb-1" src="/images/card.png" height="15" alt="Cards">Cartões</div>
                                                <div class="w-100"></div>
                                                <div class="col text-center text-theme">${total_corners}</div>
                                                <div class="col text-center text-theme">${total_cards}</div>
                                            </div>    
                                        </div>
                                    </div>
                                ` : hun.id != anteior_value_team && hun.sport != 1 && hun.sport != 3 && hun.sport != 8 && (hun.situation == 1 || hun.situation == 2) ? `
                                    <div class="row w-100 mb-2">
                                        <div class="col-12 text-center text-theme pt-2">Resultado</div>
                                        <div class="w-100"></div>
                                        <div class="col text-center pt-2">Final</div>
                                        <div class="w-100"></div>
                                        <div class="col text-center text-theme">${hun.scoreboards.ft}</div>
                                    </div>
                                ` : ''}
                                ${hun.message ? `
                                    <div class="alert alert-warning text-center mb-0" role="alert">${hun.message}</div>
                                ` : `
                                    <div class="alert ${hun.situation == 1 || hun.status == 'g' ? 'alert-success' : hun.situation == 2 || hun.status == 'p' ? 'alert-danger' : 'alert-dark'} text-center mb-0" role="alert">${hun.market}</div>    
                                `}
                            </div>
                        </div>
                        `;

                        anteior_value_team = hun.id;
                    }

                    this.template = `
                        <div class="row align-items-center position-sticky">
                            <div class="col-auto">
                                <a class="text-theme display-5" href="/">
                                    <i class="fas fa-long-arrow-left"></i>    
                                </a>
                            </div>
                            <div class="col pl-0 pr-0">
                                <div class="logo">
                                    <a class="mr-2" href="/"><img src="` + this.logo + `" width="200" /></a>
                                </div><!-- /logo -->    
                            </div>
                            <div class="col-auto">
                                <div class="dropdown show">
                                    <a class="text-theme display-5" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <h6 class="dropdown-header">Suporte</h6>
                                        <a class="dropdown-item" href="#service">
                                            <i class="fas fa-headset mr-2"></i>Atendimento
                                        </a>
                                        <h6 class="dropdown-header">Leia com atenção</h6>
                                        <a class="dropdown-item" href="/#rules">
                                            <i class="fas fa-book-open mr-2"></i>Regulamento
                                        </a>
                                        <a class="dropdown-item" href="/#betting-rules">
                                            <i class="fas fa-shield-alt mr-2"></i>Regras de aposta
                                        </a>
                                        <div class="dropdown-divider"></div>
                                        <a class="dropdown-item" href="#share">
                                            <i class="fas fa-share mr-2"></i>Compartilhar aposta
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div><!-- /row -->

                        ${bet.situation == 1 && !bet.give_money_back ? `
                            <div class="row mb-3">
                                <div class="col">
                                    <a class="btn btn-block btn-outline-danger" href="/streaming/${this.code}">
                                        <span class="blink-animation"><i class="fas fa-signal-stream mr-2"></i>Acompanhe ao vivo</span>
                                    </a>
                                </div>
                            </div>
                            ` : bet.give_money_back ? `
                                <div class="alert text-center mb-3 alert-warning">
                                    <i class="fas fa-hands-usd mr-2"></i>Aposta devolvida
                                </div>
                            ` : `
                            <div class="alert text-center mb-3 ${bet.situation == 2 ? 'alert-success' : bet.situation == 3 ? 'alert-danger' : bet.situation == 4 ? 'alert-secondary' : 'hide'}">
                                ${bet.situation == 2 ? '<i class="fas fa-trophy mr-2"></i>Aposta premiada' : bet.situation == 3 ? '<i class="fas fa-sad-tear mr-2"></i>Aposta perdida' : bet.situation == 4 ? '<i class="fas fa-ban mr-2"></i>Aposta cancelada' : 'Sem informações'}
                            </div>
                        `}

                        ${this.sorteio.ativo ? `
                            <div class="alert alert-warning" role="alert">
                                <div class="text-center">
                                    <strong>Sua aposta está participando de um sorteio!</strong>
                                </div>
                                <a class="btn btn-warning btn-block mt-3" data-toggle="collapse" href="#collapseSorteio" role="button" aria-expanded="false" aria-controls="collapseSorteio">
                                    ${this.isMobile() == 'desktop' ? 'Clique aqui e saiba mais...' : 'Aperte aqui e saiba mais...'}
                                </a>
                                <div class="collapse mt-3" id="collapseSorteio">
                                    <div class="card border-warning">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm">Prêmio:</div>
                                                <div class="col-sm text-muted">${this.sorteio.nome}</div>
                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-sm">Horário do sorteio:</div>
                                                <div class="col-sm text-muted">${this.sorteio.data_sorteio}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>     
                        ` : `
                            ${this.sorteio.ganhou ? `
                                <div class="alert alert-success" role="alert">
                                    <div class="text-center">
                                        <strong>Sua aposta foi sorteada e você ganhou: <p class="text-uppercase mb-0">${this.sorteio.nome}</p></strong>
                                        <hr />
                                        <p class="mb-0">Entre em contato com o suporte para resgatar seu prêmio.</p>
                                    </div>
                                    <a class="btn btn-warning btn-block mt-3" href="#service" role="button">
                                        <i class="fas fa-headset mr-2"></i>Suporte
                                    </a>
                                </div>
                            ` : ''}
                        `}

                        ${this.isMobile() == 'desktop' ? `
                            <div class="row">
                                <div class="col text-white text-truncate">Código</div>
                                <div class="col text-white text-truncate text-center">Horário</div>
                                <div class="col text-white text-truncate text-right">Vendedor</div>
                            </div><!-- /row -->
                            <div class="row">
                                <div class="col text-style text-theme text-truncate text-uppercase">${bet.code}</div>
                                <div class="col text-style text-theme text-truncate text-center">${[this.convertDate(bet.date), bet.hour].join(' às ')}</div>
                                <div class="col text-style text-theme text-truncate text-right">${bet.salesman}</div>
                            </div><!-- /row -->
                            <div class="w-100 border-top border-dark mt-3 mb-3"></div>
                            <div class="row">
                                <div class="col text-white text-truncate">Valor</div>
                                <div class="col text-white text-truncate text-center">Possível prêmio</div>
                                <div class="col text-white text-truncate text-right">Apostador</div>
                            </div><!-- /row -->
                            <div class="row">
                                <div class="col text-style text-theme text-truncate">R$ ${this.formatCoin(bet.value)}</div>
                                <div class="col text-style text-theme text-truncate text-center">R$ ${this.formatCoin(bet.premium)}</div>
                                <div class="col text-style text-theme text-truncate text-right">${bet.punter}</div>
                            </div><!-- /row -->
                        ` : `
                            <div class="row">
                                <div class="col text-white text-truncate">Código</div>
                                <div class="col text-white text-truncate text-right">Horário</div>
                            </div><!-- /row -->
                            <div class="row">
                                <div class="col text-style text-theme text-truncate text-uppercase">${bet.code}</div>
                                <div class="col text-style text-theme text-truncate text-right">${[this.convertDate(bet.date), bet.hour].join(' às ')}</div>
                            </div><!-- /row -->
                            <div class="w-100 border-top border-dark mt-3 mb-3"></div>
                            <div class="row">
                                <div class="col text-white text-truncate">Vendedor</div>
                                <div class="col text-white text-truncate text-right">Apostador</div>
                            </div><!-- /row -->
                            <div class="row">
                                <div class="col text-style text-theme text-truncate">${bet.salesman}</div>
                                <div class="col text-style text-theme text-truncate text-right">${bet.punter}</div>
                            </div><!-- /row -->
                            <div class="w-100 border-top border-dark mt-3 mb-3"></div>
                            <div class="row">
                                <div class="col text-white text-truncate">Valor</div>
                                <div class="col text-white text-truncate text-right">Possível prêmio</div>
                            </div><!-- /row -->
                            <div class="row">
                                <div class="col text-style text-theme text-truncate">R$ ${this.formatCoin(bet.value)}</div>
                                <div class="col text-style text-theme text-truncate text-right">R$ ${this.formatCoin(bet.premium)}</div>
                            </div><!-- /row -->
                        `}

                        ${this.goCashout()}

                        <div class="clearfix w-100">
                            <div class="w-100 border-top border-dark mt-3 mb-3"></div>
                            <h4 class="text-center text-uppercase text-theme">Qtd. Palpites: ${this.countTotalHunches(hunches)}</h4>
                            ${html}
                        </div>

                        ${this.commission != 0 ? `
                            <div class="alert alert-info mt-3 text-center" role="alert">
                                <div>Vendedor paga</div>
                                <h5 class="font-bold">R$ ${this.formatCoin(bet.premium - commission)}</h5>
                                <p class="mb-0">
                                    <span>O vendedor receberá <strong>${this.commission}%</strong> referente a premiação!</span>
                                    <hr />
                                    <span>Ao apostar em nosso site, você está concordando com nossas <a href="/#rules">regras</a>.</span>
                                </p>
                            </div>
                        ` : ''}
                    `;
                }
                else
                {
                    this.alert.show = true;
                    this.alert.message = 'Não foi possível abrir essa aposta, atualize a página e tente novamente!';
                }
            } 
            catch(e) 
            {
                this.alert.show = true;
                this.alert.message = 'Não foi possível abrir essa aposta, atualize a página e tente novamente!';
            }
        },
        convertHour(date) {
            return moment(date).tz('America/Recife').format('HH:mm');
        },
        convertDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YY');
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        getTeamImage(team) {
            try {
                return `${this.image_server}/s/${this.removeAccents(team).toLowerCase().trim().replace(/[^a-z-0-9\s]/g, '').replace(/\s/g, '-')}.png`;
            } catch(e) {
                return 'shield.png';
            }
        },
        clearURLHash() {
            try {
                window.location.hash = '';
                history.replaceState(null, null, ' ');
            } catch(e) {
                window.sessionStorage.setItem('error-clear-url', e.message);
            }
        }
    },
    watch: {
        code(value) {
            this.code = value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        },
        '$route': function(route) {
            if (route.hasOwnProperty('hash')) {
                if (route.hash == '#service') {
                    window.location.href = `https://api.whatsapp.com/send?phone=${encodeURIComponent(`+55${this.whatsapp.toString().replace(/[^\d]/g, "")}`)}&text=${encodeURIComponent(`Olá, ${document.title}`)}`;
                } else if (route.hash == '#share') {
                    this.clearURLHash();
                    try {
                        navigator.share({
                            title: document.title,
                            url: document.URL
                        });
                    } catch(e) {
                        window.location.href = `whatsapp://send?text=${encodeURIComponent(document.title + '\n\n' + document.URL)}`;
                    }
                }
            }
            this.clearURLHash();
        }
    },
    beforeMount() {
        this.loading = true;
    },
    mounted() {
        const body = document.getElementsByTagName('body')[0];
        if (body) body.className += 'theme-bg';

        window.imageCheck = (e) => {
            try {
                var w = e.naturalWidth;
                var h = e.naturalHeight;

                if (w == 1 && h == 1) {
                    e.src = '/images/shield_default.png';
                }
            } catch(e) {
                e.src = '/images/shield_default.png';
            }
        }
    },
    created() {
        this.ticketByCode(this.$route.params.code);
    }
}
</script>

<style scoped>
.body {
    width: 100%;
    height: calc(100% + (100vh));
    background-color: var(--background);
}
.body.bg-none {
    background-color: transparent;
}
.navbar {
    line-height: 46px;
}
.ticket-online {
    max-width: 500px;
    margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .ticket-online {
    max-width: 100%;
    margin: 0 1rem;
  }
}
</style>